<template>
  <div
    class="smallVideoPlayer"
    v-if="config.path != ''"
    v-loading="videoLoading"
    ref="videoPlayerBox"
    :style="{ height: `${playerBoxHeight}px` }"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <video
      ref="videoPlayer"
      :src="config.path"
      webkit-playsinline="true"
      playsinline="true"
      x-webkit-airplay="true"
      x5-video-player-type="h5"
      x5-video-player-fullscreen="true"
      x5-video-orientation="portraint"
      style="object-fit:contain"
      @loadedmetadata="videoLoadedmetadata"
      @timeupdate="videoTimeupdate"
    >
      your browser does not support the video tag
    </video>
    <div
      class="videoOperationLayer"
      v-if="!alreadyPlayed"
      @click.stop="playVideo(true)"
    >
      <div class="videoStatus">
        <span class="iconfont_Me icon-play2-fill videoStatusIconStyle"></span>
      </div>
    </div>
    <div
      v-else
      class="videoOperationLayer"
      :style="{
        opacity:
          movementVideo || (isShowOnFloatingWindow && !nowIsOnFloatingvideo)
            ? 1
            : 0,
        background:
          isShowOnFloatingWindow && !nowIsOnFloatingvideo
            ? 'rgba(0,0,0,0.5)'
            : 'transparent',
      }"
      @click.stop="playVideo(true)"
      @mouseenter="videoMouseenter"
      @mouseleave="videoMouseleave"
    >
      <div
        class="deleteThisVideo"
        @click.stop="closeFloatingWindow"
        v-if="nowIsOnFloatingvideo"
      >
        <span class="iconfont_Me icon-x deleteStyle"></span>
      </div>
      <div
        class="videoStatus"
        @click.stop="playVideo(true)"
        v-if="
          !isNowPlaying && (!isShowOnFloatingWindow || nowIsOnFloatingvideo)
        "
      >
        <span class="iconfont_Me icon-play2-fill videoStatusIconStyle"></span>
      </div>
      <div
        class="videoStatus"
        v-if="isShowOnFloatingWindow && !nowIsOnFloatingvideo"
      >
        <span class="iconfont_Me icon-zoomout videoStatusIconStyle"></span>
      </div>
      <div
        class="videoControl"
        v-if="!isShowOnFloatingWindow || nowIsOnFloatingvideo"
        :class="
          config.type == 'small'
            ? 'smallControl'
            : config.type == 'exquisite'
            ? 'exquisiteControl'
            : ''
        "
        @click.stop=""
      >
        <div
          class="sliderOuter"
          @mouseenter="sliderMouseenter"
          @mouseleave="sliderMouseleave"
        >
          <el-slider
            v-model="currentTimeVal"
            :class="movementSlider ? 'movementOn' : 'movementOff'"
            @change="changeSlider"
            :show-input-controls="false"
            :show-tooltip="false"
            :max="durationProgress"
          ></el-slider>
        </div>
        <div class="controlInner">
          <div class="controlLeft">
            <div class="smallVideoStatus" @click.stop="playVideo(false)">
              <span
                class="iconfont_Me videoStatusIconStyle"
                :class="!isNowPlaying ? 'icon-play2-fill' : 'icon-zanting'"
              ></span>
            </div>
            <div class="timeTextShow">{{ currentTime }}/{{ totalTime }}</div>
          </div>
          <div class="controlRight">
            <div
              class="controlButton"
              v-if="config.floatingWindowPlay"
              @click.stop="setfloatingWindow"
            >
              <span
                class="iconfont_Me buttonStyle"
                :class="isShowOnFloatingWindow ? 'icon-zoom' : 'icon-zoomout'"
              ></span>
            </div>
            <div
              class="controlButton"
              v-if="config.zoomControl"
              @click.stop="goLargeVideo"
            >
              <span class="iconfont_Me icon-fangda buttonStyle"></span>
            </div>
            <div
              class="controlButton"
              v-if="config.downloadControl"
              @click.stop="downLoadVideo"
            >
              <span class="iconfont_Me icon-download buttonStyle"></span>
            </div>
            <div
              class="controlButton volumeButton"
              @click.stop="openVolumePopover"
              v-if="config.volumeControl"
            >
              <span
                class="iconfont_Me buttonStyle"
                :class="volume != 0 ? 'icon-un-mute' : 'icon-Mute'"
              ></span>
              <div class="volumePopover" v-if="volumePopover">
                <el-slider
                  vertical
                  height="70px"
                  v-model="volume"
                  :max="100"
                  @input="changeVolume"
                ></el-slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      movementSlider: false, // 鼠标移动上进度条
      movementVideo: false, // 鼠标移动到视频上
      videoMouseTimer: null, // 鼠标是否移出视频的定时器
      videoLoading: false, // 视频初始化
      videoPlayer: null, // 当前视频播放器对象
      volume: 50, // 默认音量0.5
      volumePopover: false, // 音频操作版面
      isNowPlaying: false, // 标记当前的播放状态,默认视频暂停状态
      currentTime: "00:00", // 当前播放时间
      totalTime: "00:00", // 总时长
      currentTimeVal: 0, // 进度条当前播放时间，必须是number类型
      durationProgress: 0, // 进度条的最大值，必须是number类型,
      playerBoxHeight: 0, //播放器盒子的高度
      alreadyPlayed: false, //已经被点击播放过
    };
  },
  props: {
    // 播放器配置信息
    config: {
      type: Object,
      default: () => {
        return {
          // 视频地址
          path: "",
          // 播放器大小设置（samll为上传视频的大小;exquisite为主页贴子展示的视频）
          type: "small",
          // 直接开始自动播放
          startPlayingDirectly: false,
          // 视频播放进度（秒）
          currentTime: 0,
          // 当前是否是悬浮播放
          isFloating: false,
          // 是否显示浮窗控制按钮
          floatingWindowPlay: false,
          // 是否展示放大按钮
          zoomControl: false,
          // 是否展示下载按钮
          downloadControl: false,
          // 音量控制按钮是否展示
          volumeControl: false,
          // 是否从帖子列表进入
          isFromTheList: false,
          // 帖子的ID（isFromTheList为true的时候，为必传值）
          gigId: "",
        };
      },
    },
    // 父组件是否来自帖子列表
    isFromPostList: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // 这个是判断当前组件是否就是悬浮窗的组件
    nowIsOnFloatingvideo() {
      return this.config.isFloating;
    },
    // 这个是判断悬浮窗是否打开，并且悬浮窗中播放的是不是当前组件的视频
    isShowOnFloatingWindow() {
      return (
        this.$store.state.videoRelated.videoFloatingWindow.path &&
        this.$store.state.videoRelated.videoFloatingWindow.path ===
          this.config.path
      );
    },
  },
  watch: {
    // 监听大弹窗对应的帖子信息ID，用于关闭大弹窗后，继续播放
    "$store.state.homePage.multimediaPopupConfig.gigId": {
      handler(val, old) {
        // 判断当前大屏关闭是不是这个视频，是的话，就接着大屏上播放的进度播放
        if (
          val === "" &&
          old === this.config.gigId &&
          this.$store.state.homePage.recordPlaybackProgress !== 0
        ) {
          // 将记录的时间赋值给当前组件
          this.config.currentTime = this.$store.state.homePage.recordPlaybackProgress;
          // 并清空记录时间
          this.$store.commit("setRecordPlaybackProgress", 0);
          // 开始准备播放
          this.initVideo();
        }
      },
      deep: true,
    },
    // 监听当前组件对应的视频从悬浮窗中撤出，继续播放
    "$store.state.videoRelated.videoFloatingWindow.path": {
      handler(val, old) {
        // 当悬浮窗最新值为空，旧值是当前播放器的地址的话，说明关闭悬浮创了，可以继续播放
        if (
          val === "" &&
          old === this.config.path &&
          !this.nowIsOnFloatingvideo
        ) {
          this.videoPlayer.currentTime = this.$store.state.videoRelated.timeRecord;
          this.$nextTick(() => {
            this.isNowPlaying = true;
          });
        }
      },
      deep: true,
    },
    // 监听播放状态
    isNowPlaying: {
      handler(val) {
        if (val && this.videoPlayer) {
          this.alreadyPlayed = true;
          this.videoPlayer.play();
          // 如果来自帖子列表的视频开始播放，则需要将其他正则播放的视频暂停
          if (
            this.isFromPostList &&
            this.config.gigId &&
            this.config.gigId !== ""
          ) {
            // 这里的记录字符串为（帖子ID，视频地址）
            this.$store.commit(
              "setVideoBeingPlayed",
              `${this.config.gigId},${this.config.path}`
            );
          }
        } else if (!val && this.videoPlayer) {
          this.videoPlayer.pause();
        }
      },
      deep: true,
    },
    // 监听视频记录更新，如果当前视频是过去的值，说明不需要在继续播放视频了，直接暂停
    "$store.state.homePage.videoBeingPlayed": {
      handler(val, old) {
        if (
          val !== old &&
          old !== "" &&
          this.isFromPostList &&
          this.config.gigId &&
          this.config.gigId !== "" &&
          old === `${this.config.gigId},${this.config.path}`
        ) {
          this.isNowPlaying = false;
        }
      },
      deep: true,
    },
  },
  created() {
    this.videoLoading = true;
  },
  beforeDestroy() {
    if (this.videoMouseTimer) {
      clearTimeout(this.videoMouseTimer);
    }
  },
  methods: {
    // 点击进入浮窗界面
    setfloatingWindow() {
      this.isNowPlaying = false;
      this.$nextTick(() => {
        // 如果悬浮窗打开且播放的视频不是本组件的视频，或者悬浮窗处于关闭状态，则开启悬浮播放
        if (
          !this.isShowOnFloatingWindow &&
          !this.nowIsOnFloatingvideo &&
          !this.videoLoading
        ) {
          let config = {
            // 视频地址
            path: this.config.path,
            // 当前播放的进度
            currentTime: this.videoPlayer.currentTime,
          };
          this.$store.commit("setVideoFloatingWindow", config);
          // 如果是在浮窗中点击，则默认关闭浮窗
        } else if (this.isShowOnFloatingWindow && this.nowIsOnFloatingvideo) {
          // 必须写前面
          this.$store.commit("setTimeRecord", this.videoPlayer.currentTime);
          let config = {
            // 视频地址
            path: "",
            // 当前播放的进度
            currentTime: 0,
          };
          this.$store.commit("setVideoFloatingWindow", config);
        }
      });
    },
    // 如果是悬浮窗，点击关闭悬浮窗
    closeFloatingWindow() {
      this.isNowPlaying = false;
      this.$nextTick(() => {
        // 必须写前面
        this.$store.commit("setTimeRecord", this.videoPlayer.currentTime);
        let config = {
          // 视频地址
          path: "",
          // 当前播放的进度
          currentTime: 0,
        };
        this.$store.commit("setVideoFloatingWindow", config);
      });
    },
    // 视频元数据加载成功，可以开始播放，并可以使用video对象的数据
    videoLoadedmetadata() {
      // 预防在快速切换的时候没有找到播放器对象报错
      try {
        this.videoLoading = false;
        this.videoPlayer = this.$refs.videoPlayer;
        // 在这里先把视频状态设置为暂停，不然在悬浮窗状态的时候无法触发监听
        this.isNowPlaying = false;
        this.playerBoxHeight = this.$refs.videoPlayerBox.offsetWidth / (16 / 9);
        this.playerBoxHeight &&
          this.playerBoxHeight != 139.5 &&
          localStorage.setItem("playerBoxHeight", this.playerBoxHeight);
        this.playerBoxHeight =
          this.$refs.videoPlayerBox.offsetWidth / (16 / 9) ||
          localStorage.getItem("playerBoxHeight");
        this.initVideo();
        this.$nextTick(() => {
          this.$emit("videoLoadSuccess");
        });
      } catch (error) {}
    },
    // 初始化视频空间
    initVideo() {
      // 获取视频基本信息
      this.getTotal();
    },
    // 开始播放视频
    playVideo(flag) {
      // 如果当前视频已经转移到悬浮窗播放，则禁止再操作这视频；如果当前就是在悬浮窗，则可以继续操作
      if (this.isShowOnFloatingWindow && !this.nowIsOnFloatingvideo) return;
      // 如果视频对象还未获取到
      if (!this.videoPlayer) return;
      this.isNowPlaying = !this.isNowPlaying;
      this.$nextTick(() => {
        // 这里是用于判断在列表中视频在播放时，点击空白区域暂停，默认跳转到大屏播放
        if (!this.isNowPlaying && flag && this.config.isFromTheList) {
          this.goLargeVideo();
        }
      });
    },
    // 鼠标移入视频
    videoMouseenter() {
      if (this.videoMouseTimer) {
        clearTimeout(this.videoMouseTimer);
      }
      this.$nextTick(() => {
        this.movementVideo = true;
      });
    },
    // 鼠标移出视频
    videoMouseleave() {
      this.videoMouseTimer = setTimeout(() => {
        this.movementVideo = false;
      }, 1500);
    },
    // 点击音频控制按钮
    openVolumePopover() {
      this.volumePopover = !this.volumePopover;
    },
    // 改变音量
    changeVolume() {
      this.videoPlayer.volume = this.volume / 100;
    },
    // 鼠标移入进度条范围
    sliderMouseenter() {
      this.movementSlider = true;
    },
    // 鼠标移出进度条范围
    sliderMouseleave() {
      this.movementSlider = false;
    },
    // 改变进度条
    changeSlider(e) {
      if (!this.videoPlayer) return;
      this.isNowPlaying = false;
      this.$nextTick(() => {
        this.videoPlayer.currentTime = this.currentTimeVal;
        this.isNowPlaying = true;
      });
    },
    //时间格式化处理
    timeFormat(time) {
      let minute = Math.floor((time % 3600) / 60);
      let second = Math.floor(time % 60);
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      return `${minute}:${second}`;
    },
    //获取总时长
    getTotal() {
      // 预防快速切换的时候报错
      try {
        // 获取过滤后的时间文本
        this.totalTime = this.timeFormat(this.videoPlayer.duration);
        this.$emit("getDuration", this.videoPlayer.duration);
        // 获取视频总时长时间戳
        this.durationProgress = this.videoPlayer.duration;
        if (this.config.currentTime != 0 || this.config.startPlayingDirectly) {
          this.$nextTick(() => {
            this.videoPlayer.currentTime = this.config.currentTime;
            this.$nextTick(() => {
              this.isNowPlaying = true;
            });
          });
        }
      } catch (error) {}
    },
    //获取当前视频播放到的时间
    videoTimeupdate() {
      if (!this.videoPlayer) return;
      if (this.videoPlayer.currentTime == this.videoPlayer.duration) {
        this.videoPlayer.currentTime = 0;
        this.isNowPlaying = false;
      }
      this.currentTime = this.timeFormat(this.videoPlayer.currentTime);
      this.currentTimeVal = this.videoPlayer.currentTime;
    },
    // 下载视频
    downLoadVideo() {
      return new Promise((resolve, reject) => {
        this.videoLoading = true;
        fetch(this.config.path)
          .then((res) => res.blob())
          .then((blob) => {
            this.videoLoading = false;
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style.display = "none";
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = Date.now();
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
            resolve("success");
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    // 前往全屏播放
    goLargeVideo() {
      // 确保当前视频已加载完成
      if (this.videoPlayer && !this.videoLoading) {
        // 暂停播放当前视频
        this.isNowPlaying = false;
        // 视频打开弹窗必须传入帖子ID，因为侧边栏需要帖子详情
        this.$nextTick(() => {
          // 深拷贝大视频数据
          let multimediaPopupConfig = _.cloneDeep(
            this.$store.state.homePage.multimediaPopupConfig
          );
          multimediaPopupConfig.videoPath = this.config.path;
          multimediaPopupConfig.videoCurrentTime = this.currentTimeVal;
          multimediaPopupConfig.gigId = this.config.gigId;
          this.$store.dispatch(
            "commitMultimediaPopupConfig",
            multimediaPopupConfig
          );
        });
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.smallVideoPlayer
  width 100%;
  height 100%;
  border-radius 8px;
  overflow hidden;
  position relative;
  background rgba(0,0,0,0.9)
  video
    width 100%;
    height 100%;
  .videoOperationLayer
    width 100%;
    height 100%;
    position absolute;
    top 0;
    left 0;
    z-index 2
    display flex;
    align-items center;
    justify-content center;
    transition opacity 0.5s;
    .deleteThisVideo
      top 5px;
      right 5px;
      width 18px;
      height 18px;
      z-index 3;
      border-radius 50%;
      position absolute;
      background rgba(0,0,0,0.2);
      display flex;
      align-items center;
      justify-content center;
      cursor pointer;
      .deleteStyle
        font-size 12px;
        color #ffffff;
    .videoStatus
      width 50px;
      height 50px;
      border-radius 50%;
      background rgba(0,0,0,0.5);
      transition transform .2s , background .2s;
      cursor pointer;
      text-align center;
      line-height 50px;
      &:hover
        background rgba(0,0,0,0.7);
      .videoStatusIconStyle
        font-size 26px;
        color #ffffff;
.videoControl
  width 100%;
  position absolute;
  left 0;
  bottom 0;
  background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.5));
  display flex;
  align-items center;
  justify-content center;
  .sliderOuter
    left 0;
    top -4px;
    width 100%;
    height 10px;
    position absolute;
    display flex;
    align-items center;
    justify-content center;
  .controlInner
    display flex;
    align-items center;
    justify-content space-between;
    .controlLeft
      height 100%;
      display flex;
      align-items center;
      justify-content flex-start;
      .smallVideoStatus
        display flex;
        cursor pointer;
        align-items center;
        justify-content center;
        .videoStatusIconStyle
          color #fff;
          font-size 20px;
    .controlRight
      height 100%;
      display flex;
      align-items center;
      justify-content flex-start;
      .controlButton
        display flex;
        align-items center;
        justify-content center;
        cursor pointer;
        .buttonStyle
          color #fff;
          font-size 20px;
      .volumeButton
        position relative;
        .volumePopover
          width 30px;
          height 90px;
          position absolute;
          top -105px;
          left 50%;
          transform translate3d(-20px,0,0);
          background rgba(0,0,0,0.5);
          border-radius 4px;
          display flex;
          align-items center;
          justify-content center;
.smallControl
  height 40px;
  .controlInner
    height 20px;
    width 100%;
    padding 0 8px;
    box-sizing border-box;
    .controlLeft
      .smallVideoStatus
        width 20px;
        height 20px;
      .timeTextShow
        color #fff;
        font-size 12px;
        margin-left 8px;
    .controlRight
      .controlButton
        width 20px;
        height 20px;
        margin-left 17px;
        &:first-child
          margin-left 0 !important;
</style>
<style lang="stylus">
.videoControl
  .sliderOuter
    .movementOn
      .el-slider__runway,.el-slider__bar
        height 4px;
      .el-slider__button-wrapper
        opacity 1;
    .movementOff
      .el-slider__runway,.el-slider__bar
        height 2px;
      .el-slider__button-wrapper
        opacity 0;
    .el-slider
      width 100%;
      .el-slider__runway
        margin 0;
        background rgba(255,255,255,0.49);
        .el-slider__bar
          border-radius 0;
        .el-slider__button-wrapper
          height: 30px;
          width: 30px;
          top -13px;
          transition opacity .2s;
          .el-slider__button
            width: 10px;
            height: 10px;
            border none;
            background #33cc66;
            border-radius: 50%;
            -webkit-transition: .2s;
            transition: .2s;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
  .volumePopover
    .el-slider
      .el-slider__runway
        margin 0;
        width 4px
        border-radius 2px;
        background rgba(255,255,255,1);
        .el-slider__bar
          border-radius 2px;
          width: 4px;
        .el-slider__button-wrapper
          height: 30px;
          width: 30px;
          left -13px;
          transition opacity .2s;
          .el-slider__button
            width: 10px;
            height: 10px;
            border none;
            background #33cc66;
            border-radius: 50%;
            -webkit-transition: .2s;
            transition: .2s;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
</style>
