var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.config.path != ""
    ? _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.videoLoading,
              expression: "videoLoading"
            }
          ],
          ref: "videoPlayerBox",
          staticClass: "smallVideoPlayer",
          style: { height: `${_vm.playerBoxHeight}px` },
          attrs: { "element-loading-background": "rgba(0, 0, 0, 0.8)" }
        },
        [
          _c(
            "video",
            {
              ref: "videoPlayer",
              staticStyle: { "object-fit": "contain" },
              attrs: {
                src: _vm.config.path,
                "webkit-playsinline": "true",
                playsinline: "true",
                "x-webkit-airplay": "true",
                "x5-video-player-type": "h5",
                "x5-video-player-fullscreen": "true",
                "x5-video-orientation": "portraint"
              },
              on: {
                loadedmetadata: _vm.videoLoadedmetadata,
                timeupdate: _vm.videoTimeupdate
              }
            },
            [_vm._v("\n    your browser does not support the video tag\n  ")]
          ),
          !_vm.alreadyPlayed
            ? _c(
                "div",
                {
                  staticClass: "videoOperationLayer",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.playVideo(true)
                    }
                  }
                },
                [_vm._m(0)]
              )
            : _c(
                "div",
                {
                  staticClass: "videoOperationLayer",
                  style: {
                    opacity:
                      _vm.movementVideo ||
                      (_vm.isShowOnFloatingWindow && !_vm.nowIsOnFloatingvideo)
                        ? 1
                        : 0,
                    background:
                      _vm.isShowOnFloatingWindow && !_vm.nowIsOnFloatingvideo
                        ? "rgba(0,0,0,0.5)"
                        : "transparent"
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.playVideo(true)
                    },
                    mouseenter: _vm.videoMouseenter,
                    mouseleave: _vm.videoMouseleave
                  }
                },
                [
                  _vm.nowIsOnFloatingvideo
                    ? _c(
                        "div",
                        {
                          staticClass: "deleteThisVideo",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.closeFloatingWindow.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        },
                        [
                          _c("span", {
                            staticClass: "iconfont_Me icon-x deleteStyle"
                          })
                        ]
                      )
                    : _vm._e(),
                  !_vm.isNowPlaying &&
                  (!_vm.isShowOnFloatingWindow || _vm.nowIsOnFloatingvideo)
                    ? _c(
                        "div",
                        {
                          staticClass: "videoStatus",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.playVideo(true)
                            }
                          }
                        },
                        [
                          _c("span", {
                            staticClass:
                              "iconfont_Me icon-play2-fill videoStatusIconStyle"
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm.isShowOnFloatingWindow && !_vm.nowIsOnFloatingvideo
                    ? _c("div", { staticClass: "videoStatus" }, [
                        _c("span", {
                          staticClass:
                            "iconfont_Me icon-zoomout videoStatusIconStyle"
                        })
                      ])
                    : _vm._e(),
                  !_vm.isShowOnFloatingWindow || _vm.nowIsOnFloatingvideo
                    ? _c(
                        "div",
                        {
                          staticClass: "videoControl",
                          class:
                            _vm.config.type == "small"
                              ? "smallControl"
                              : _vm.config.type == "exquisite"
                              ? "exquisiteControl"
                              : "",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "sliderOuter",
                              on: {
                                mouseenter: _vm.sliderMouseenter,
                                mouseleave: _vm.sliderMouseleave
                              }
                            },
                            [
                              _c("el-slider", {
                                class: _vm.movementSlider
                                  ? "movementOn"
                                  : "movementOff",
                                attrs: {
                                  "show-input-controls": false,
                                  "show-tooltip": false,
                                  max: _vm.durationProgress
                                },
                                on: { change: _vm.changeSlider },
                                model: {
                                  value: _vm.currentTimeVal,
                                  callback: function($$v) {
                                    _vm.currentTimeVal = $$v
                                  },
                                  expression: "currentTimeVal"
                                }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "controlInner" }, [
                            _c("div", { staticClass: "controlLeft" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "smallVideoStatus",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.playVideo(false)
                                    }
                                  }
                                },
                                [
                                  _c("span", {
                                    staticClass:
                                      "iconfont_Me videoStatusIconStyle",
                                    class: !_vm.isNowPlaying
                                      ? "icon-play2-fill"
                                      : "icon-zanting"
                                  })
                                ]
                              ),
                              _c("div", { staticClass: "timeTextShow" }, [
                                _vm._v(
                                  _vm._s(_vm.currentTime) +
                                    "/" +
                                    _vm._s(_vm.totalTime)
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "controlRight" }, [
                              _vm.config.floatingWindowPlay
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "controlButton",
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.setfloatingWindow.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "iconfont_Me buttonStyle",
                                        class: _vm.isShowOnFloatingWindow
                                          ? "icon-zoom"
                                          : "icon-zoomout"
                                      })
                                    ]
                                  )
                                : _vm._e(),
                              _vm.config.zoomControl
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "controlButton",
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.goLargeVideo.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "iconfont_Me icon-fangda buttonStyle"
                                      })
                                    ]
                                  )
                                : _vm._e(),
                              _vm.config.downloadControl
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "controlButton",
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.downLoadVideo.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "iconfont_Me icon-download buttonStyle"
                                      })
                                    ]
                                  )
                                : _vm._e(),
                              _vm.config.volumeControl
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "controlButton volumeButton",
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.openVolumePopover.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "iconfont_Me buttonStyle",
                                        class:
                                          _vm.volume != 0
                                            ? "icon-un-mute"
                                            : "icon-Mute"
                                      }),
                                      _vm.volumePopover
                                        ? _c(
                                            "div",
                                            { staticClass: "volumePopover" },
                                            [
                                              _c("el-slider", {
                                                attrs: {
                                                  vertical: "",
                                                  height: "70px",
                                                  max: 100
                                                },
                                                on: { input: _vm.changeVolume },
                                                model: {
                                                  value: _vm.volume,
                                                  callback: function($$v) {
                                                    _vm.volume = $$v
                                                  },
                                                  expression: "volume"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ])
                        ]
                      )
                    : _vm._e()
                ]
              )
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "videoStatus" }, [
      _c("span", {
        staticClass: "iconfont_Me icon-play2-fill videoStatusIconStyle"
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }